<template>
  <div>
    <Group
      :task="task"
      :disabled="!hasEditPermission"
      @change:group="changeGroup"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Group from "@/flow/views/components/commons/task/group";

export default {
  components: { Group },
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("flowTasks", ["hasEditPermission"])
  },
  methods: {
    ...mapActions("flowTasks", ["updateGroupId"]),
    changeGroup(groupId) {
      const { id: taskId, groupId: prevGroupId } = this.task;
      this.updateGroupId({ taskId, groupId, prevGroupId });
    }
  }
};
</script>
