<template>
  <CrSelect
    :items="groups"
    :value.sync="_group"
    :menuMaxWidth="width"
    :disabled="!hasEditPermission"
  />
</template>

<style lang="scss" scoped>
.cr-flow-select.v-input.v-text-field::v-deep {
  border-radius: 8px !important;
  fieldset {
    border-color: rgba(0, 0, 0, 0.12);
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import CrSelect from "@/flow/views/components/commons/select";

export default {
  components: { CrSelect },
  emits: ["change:group"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    if (!this.task.groupId) {
      const { id: groupId } = this.groups.find(g => g.isDefault);
      this.$emit("change:group", groupId);
    }

    // dialog 때문에 이벤트 미뤄준다.
    setTimeout(() => {
      this.width = this.$el.offsetWidth;
    }, 100);
  },
  data({ $props: { task } }) {
    return { groupId: task?.groupId, width: 0 };
  },
  computed: {
    ...mapGetters("flowTasks", ["groups", "hasEditPermission"]),
    _group: {
      get() {
        let group = this.groups.find(g => g.id == this.groupId);
        if (!group) group = this.groups.find(g => g.isDefault);
        return group;
      },
      set(groupId) {
        this.$emit("change:group", groupId);
      }
    }
  }
};
</script>
